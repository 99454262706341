import axios from 'axios';
import localStorageService from './localStorageService';

import {host} from './../backend';

class JwtAuthService {
  user = {
    role: 'ADMIN'
  };

  loginWithEmailAndPassword = async (email, password) => {
    try {
      const response = await axios.post(`${host}/login`, {user: email, pass: password});
      const {token, user} = response.data;

      this.user.token = token;
      this.user.tipo = user;

      this.setSession(token);
      this.setUser(this.user);

      return this.user;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };
  loginWithToken = () => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      this.setSession(token);
      const user = localStorageService.getItem('auth_user');
      return user ? Promise.resolve(user) : Promise.reject('No user found in session.');
    }
    return Promise.reject('No token found.');
  };
  logout = () => {
    this.setSession(null);
    this.removeUser();
    window.location.href = '/'; // Redirect to login
  };

  setSession = token => {
    if (token) {
      localStorage.setItem('jwt_token', token);
      axios.defaults.headers.common['Authorization'] = token;
    } else {
      localStorage.removeItem('jwt_token');
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  setUser = user => {
    localStorageService.setItem('auth_user', user);
  };

  removeUser = () => {
    localStorage.removeItem('auth_user');
    localStorage.removeItem('sede');
    localStorage.removeItem('organizacion');
    localStorage.removeItem('supergestor');
    localStorage.removeItem('item-id');
  };
}

export default new JwtAuthService();
