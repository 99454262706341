// DashboardContext.js
import React, {createContext, useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {host} from '../../../backend';

export const DashboardContext = createContext();

export const DashboardProvider = ({children}) => {
  const [cardData, setCardData] = useState([]);
  const [nombre, setNombre] = useState('');
  const [siteType, setSiteType] = useState(null);
  const authUser = JSON.parse(localStorage.getItem('auth_user'));
  const headers = {
    Authorization: localStorage.getItem('jwt_token'),
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  const fetchSiteType = async () => {
    try {
      const response = await axios.post(
        host + '/onlysede',
        {
          sede: Number(localStorage.getItem('sede'))
        },
        {headers}
      );

      if (response.data.resp && response.data.resp.length > 0) {
        setSiteType(response.data.resp[0].tipo);
      } else {
        console.warn("Response 'resp' array is empty or undefined:", response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (authUser && authUser.tipo !== 0) {
      if (authUser.tipo !== 0) {
        let isMounted = true;
        const fetchCardData = async () => {
          try {
            const sedeResponse = await axios.post(
              host + '/onlysede',
              {
                sede: Number(localStorage.getItem('sede'))
              },
              {headers}
            );

            const itemsResponse = await axios.post(
              host + '/items',
              {
                sedeid: localStorage.getItem('sede')
              },
              {headers}
            );

            if (itemsResponse.data.items && isMounted) {
              const cardList = itemsResponse.data.items.map(item => ({
                id: item.id,
                subtitle: item.nombre,
                icon: item.icon
              }));

              const nombre = sedeResponse.data.resp && sedeResponse.data.resp.length > 0 ? sedeResponse.data.resp[0].nombre : '';

              setCardData(cardList);
              console.log('cardList:', cardList);
              localStorage.setItem('item-id', cardList[0].id);
              setNombre(nombre);
            } else {
              console.error('itemsResponse.data.items is undefined:', itemsResponse.data);
            }
          } catch (error) {
            console.error(error);
          }
        };

        fetchSiteType();
        fetchCardData();

        return () => {
          isMounted = false;
        };
      }
    }
  }, []);

  return <DashboardContext.Provider value={{cardData, nombre, siteType}}>{children}</DashboardContext.Provider>;
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
